import { H, Section } from "@jfrk/react-heading-levels";
import { PageGrid, PageGridItem } from "@whitespace/components";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import clsx from "clsx";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

import SEO from "../../../components/SEO";
import * as styles from "../components/articleLayouts/DefaultArticleLayout.module.css";

export default function Error404Page() {
  const { t } = useTranslation();

  const { field404ErrorMessage: title, field404ErrorInfo: body } =
    useStaticQuery(graphql`
      {
        wp {
          acfOptions404 {
            group404 {
              field404ErrorMessage
              field404ErrorInfo
            }
          }
        }
      }
    `)?.wp?.acfOptions404?.group404 || {};

  return (
    <>
      <SEO title={title || t(`pageNotFound`)} />
      <PageGrid className={clsx(styles.component)}>
        <PageGridItem>
          <div className={clsx(layout.component)}>
            <div className={styles.articleContentContainer}>
              <div className={clsx(styles.header)}>
                <H>{title || t(`pageNotFound`)}</H>
              </div>
              <Section>{!!body && <HTML>{body}</HTML>}</Section>
            </div>
          </div>
        </PageGridItem>
      </PageGrid>
    </>
  );
}
